import MCPlayer from './player.js';

var MCAudioPlayer = function(container, options) {
    MCPlayer.call(this, container, options);
};

MCAudioPlayer.get = function(id) {
    return MCPlayer.get(id);
};

MCAudioPlayer.prototype = $.extend({}, MCPlayer.prototype, {
    type: 'audio',
    defaultOptions: {
        autoRender: true,
        swfPath: '/assets/js/jplayer',
        solution: 'html,flash',
        cssSelectorAncestor: '#audio-interface',
        assetFile: '',
        trackEngagement: true,
        sessionEventId: ''
    },

    initialize: function(options) {
        this.render();
    },

    render: function() {
        var self = this,
        $container = self.$container;
        $container.data('player-id', self.id);
        $container.jPlayer({
            swfPath: self.options.swfPath,
            solution: self.options.solution,
            cssSelectorAncestor: self.options.cssSelectorAncestor,
            ready: function() {
                $container.jPlayer('setMedia', {
                    mp3: self.options.assetFile
                });
                self.onBackendReady(true);

                if (self.options.autoplay) {
                    $container.jPlayer('play');
                };
            }
        });
    },

    destroy: function() {
        this.$container.jPlayer('destroy');
    },

    clearMedia: function() {
        this.$container.jPlayer('clearMedia');
    }
});

export default MCAudioPlayer;
