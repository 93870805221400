var MCTRACKABLE_TAG = '[MCTrackable]';

var MCTrackable = function(options) {
	this.trackingUuid = null;
	if (window.mcTrackingId) {
		this.trackingUuid = window.mcTrackingId;
	}
	if ('factHash' in options) {
		this.trackingUuid = options.factHash;
	}
	if (!this.trackingUuid) {
		this.trackingUuid = UUID.generate();
	}

    options = options || {};
    this.options = $.extend({}, this.defaultOptions || {}, options);

    this.id = this.prepareId();
    this.options.id = this.id;

    MCTrackable.__instances[this.id] = this;

    this.log('Created', this.id);
};

MCTrackable.__instances = {};
MCTrackable.get = function(id) {
    return MCTrackable.__instances[id];
};

MCTrackable.prototype = {
    log: function() {
        var args = Array.prototype.slice.call(arguments);
        args.splice(0, 0, MCTRACKABLE_TAG);
        args.splice(1, 0, '[' + this.trackingUuid + ']');
        console.debug.apply(console, args);
    },

    prepareId: function() {
        var id;
        if (!('id' in this.options)) {
            id = 'mc-trackable-' + this.trackingUuid;
        } else {
            id = this.options.id;
        }

        return id;
    },

    destroy: function() {
        delete MCTrackable.__instances[this.options.id];
        this.log('Deleted');
    },

    getEventData: function() {
        return this.options.eventData || {};
    },

    trackEvent: function(duration) {
        var data = $.extend({}, {
            event: '',
            factHash: this.trackingUuid,
            duration: duration || 0,
            assetKey: this.options.assetKey
        }, this.getEventData());

        if(this.options.eventId) {
            data.eventID = this.options.eventId;
        }else if(this.options.postId) {
            data.postID = this.options.postId;
        }

        if(data.assetID && data.assetKey){
            delete data.assetKey;
        }

        var cleanData = {};
        $.each(data, function(key, value) {
            if (!!value) {
                cleanData[key] = value;
            }
        });

        if(cleanData.eventID){
            cleanData.event = 'player-view';
        }else if(cleanData.postID){
            cleanData.event = 'post-view';
        }

        this.log('Sending event', cleanData);
        if(cleanData.event != '') {
            window.roqa('trackUnstructEvent', cleanData);
        }
    }
};

export default MCTrackable;
