import MCTrackable from './trackable.js';

var MCTimedTrackable = function(options) {
    MCTrackable.call(this, options);

    this.mark();
};

MCTimedTrackable.get = MCTrackable.get;
MCTimedTrackable.prototype = $.extend({}, MCTrackable.prototype, {
    mark: function() {
        this.start = $.now();
        this.log('Mark set', this.start);
    },

    getDuration: function() {
        var duration = Math.ceil(($.now() - this.start)/1000);
        this.log('Current duration', duration);
        return duration;
    },

    trackEventDuration: function() {
        this.trackEvent(this.getDuration());
    }
});

export default MCTimedTrackable;
