import MCTrackable from './trackable.js'
import UUID from './uuid.js'
var DURATIONS_DIFF_THRESHOLD = 60
var MCPlayer = function(container, options) {
	this.$container = $(container)

	options = $.extend({}, this.$container.data(), options || {})
	MCTrackable.call(this, options)

	this.backend = null
	this.__eventsToBind = []
	this.__currentTime = 0
	this.mcContentViewSessionEventDurations = []
	this.lastSentDurationIndex = 0

	this.onBackendReady = $.proxy(this.onBackendReady, this)

	this.initialize(this.options)
}

MCPlayer.get = MCTrackable.get
MCPlayer.prototype = $.extend({}, MCTrackable.prototype, {
	defaultOptions: {},

	prepareId: function() {
		return 'mc-' + this.type + '-' + UUID.generate()
	},

	render: function() {},

	$: function(selector) {
		return this.$container.find(selector)
	},

	on: function(eventName, fn) {
		if (this.backend) {
			if (this.type === 'video') {
				this.backend.on(eventName, fn)
			} else if (this.type === 'audio') {
				this.$container.bind($.jPlayer.event[eventName], fn)
			}
		} else {
			this.__eventsToBind.push([eventName, fn])
		}
	},

	play: function() {
		if (this.type === 'video') {
			this.backend.play()
		} else if (this.type === 'audio') {
			this.$container.jPlayer('play')
		}
	},

	pause: function() {
		if (this.type === 'video') {
			this.backend.pause()
		} else if (this.type === 'audio') {
			this.$container.jPlayer('pause')
		}
	},

	onBackendReady: function(instance) {
		if (this.type === 'video') {
			this.backend = instance
		} else if (this.type === 'audio') {
			this.backend = true
		}
		this.on('play', $.proxy(this.onPlay, this))
		if (this.options.trackEngagement) {
			this.on('timeupdate', $.proxy(this.onTimeUpdate, this))
			var proxiedForceSubmitDurations = $.proxy(
				this.forceSubmitDurations,
				this
			)
			this.on('pause', proxiedForceSubmitDurations)
			this.on('stop', proxiedForceSubmitDurations)
			$(window).on('beforeunload', function() {
				proxiedForceSubmitDurations.call()
			})
		}
		var self = this
		$.each(this.__eventsToBind, function() {
			self.on(this[0], this[1])
		})
		this.__eventsToBind = []
	},

	currentTime: function() {
		if (arguments.length !== 0) {
			var newValue = arguments[0]
			if (this.type === 'video') {
				this.backend.currentTime(newValue)
			} else if (this.type === 'audio') {
				this.$container.jPlayer('play', newValue)
			}
			return newValue
		} else {
			return this.__currentTime
		}
	},

	onPlay: function() {
		if (!this.mcContentViewSessionEvent && this.options.sessionEventId) {
			var self = this
			$.ajax({
				type: 'POST',
				url: '/api/beta/trackcontentview',
				dataType: 'json',
				async: false,
				data: {
					sessionEventParentID: this.options.sessionEventId,
					assetKey: this.options.assetKey,
				},
				success: function(data, status) {
					if (data.success) {
						self.mcContentViewSessionEvent = data.sessionEvent
					}
				},
			})
		}

		this.trackEvent(0)
	},

	forceSubmitDurations: function() {
		this.submitDurations(true)
	},

	submitDurations: function(force) {
		var durationsDiff =
			this.mcContentViewSessionEventDurations.length -
			this.lastSentDurationIndex
		if (durationsDiff > DURATIONS_DIFF_THRESHOLD || force) {
			var durationsToSent = this.mcContentViewSessionEventDurations.slice(
				this.lastSentDurationIndex
			)
			this.lastSentDurationIndex = this.mcContentViewSessionEventDurations.length
			if (durationsToSent.length > 0) {
				$.ajax({
					type: 'POST',
					url: '/api/beta/updatecontentviewduration',
					dataType: 'json',
					data: {
						sessionEventID: this.mcContentViewSessionEvent
							.sessionEventID,
						duration_set: durationsToSent,
					},
				})
			}

			this.trackEvent(
				this.mcContentViewSessionEventDurations[
					this.lastSentDurationIndex - 1
				]
			)
		}
	},

	onTimeUpdate: function(event) {
		if (this.type === 'video') {
			this.__currentTime = this.backend.currentTime()
		} else if (this.type === 'audio') {
			this.__currentTime = event.jPlayer.status.currentTime
		}
		if (
			this.mcContentViewSessionEvent &&
			this.mcContentViewSessionEvent.sessionEventID
		) {
			var duration = Math.floor(this.__currentTime)
			if (
				!this.mcContentViewSessionEventDurations.length ||
				duration !=
					this.mcContentViewSessionEventDurations[
						this.mcContentViewSessionEventDurations.length - 1
					]
			) {
				this.mcContentViewSessionEventDurations.push(duration)
				this.submitDurations()
			}
		}
	},
})

export default MCPlayer
