class MCAssetUploadButton {
	constructor(element, options) {
		this.id = 'mc-asset-upload-' + $.now();
		this.options = $.extend({
			data: {},
			acceptedFiles: '',
			statusElement: '',
			successCallback: $.noop,
			errorCallback: $.noop
		}, options);
		this.$element = $(element);
		this.$status = $(options.statusElement);
    if (this.$element[0].dropzone) {
      this.$element[0].dropzone.off();
      this.$element[0].dropzone.destroy();
    }
    this.dropzone = null;
    var self = this;
		new Dropzone(this.$element[0], {
			url: '/api/public/addasset',
			maxFilesize: 2000,
			clickable: true,
			capture: null,
			previewsContainer: '',
			autoProcessQueue: true,
			paramName: 'assetFile',
			acceptedFiles: this.options.acceptedFiles,
			init: function () {
				self.dropzone = this;
				self.dropzoneInit();
			}
		});
	}

	dropzoneInit() {
		this.dropzone.on('sending', $.proxy(this.dropzoneOnSending, this));
		this.dropzone.on('uploadprogress', $.proxy(this.dropzoneOnUploadProgress, this));
		this.dropzone.on('success', $.proxy(this.dropzoneOnSuccess, this));
		this.dropzone.on('error', $.proxy(this.dropzoneOnError, this));
	}

	dropzoneOnSending(file, xhr, formData) {
		$.each(this.options.data, function (key, value) {
			formData.append(key, value);
		});
		this.$status.html(mc.fn.getLznString('Uploading'));
	}

	dropzoneOnUploadProgress(file, progress, bytesSent) {
		progress = Math.ceil(progress);
		this.$status.html(
			progress == 100 || progress == 0
				? mc.fn.getLznString('Processing')
				: mc.fn.getLznString('[[percent]]% Uploaded', { percent: progress })
		);
	}

	dropzoneOnSuccess(file, response) {
		if (response.success && response.asset) {
			this.$status.html(mc.fn.getLznString('Upload'));
			this.options.successCallback(response);
		} else {
			this.$status.html(mc.fn.getLznString('Upload'));
			this.options.errorCallback(response);
		}
	}

	dropzoneOnError(file, errorMessage, xhr) {
		this.$status.html(errorMessage);
	}
}


export default MCAssetUploadButton;
